import React from "react";
import events_top_img from "../images/events_top_img.svg";
import events_bottom_img from "../images/events_bottom_img.svg";
function Events() {
  return (
    <div className="container mt-5" style={{ height: "532px" }}>
      <div className="row mt-5">
        <div
          className="col-md-5 d-flex flex-column position-relative"
          style={{ background: "#EDEDED", height: "532px" }}
        >
          <img
            src={events_top_img}
            className="position-absolute top-0 end-0"
          ></img>
          <img
            src={events_bottom_img}
            className="position-absolute bottom-0 start-0"
          ></img>
          <div className="my-auto">
            <div
              className=" ps-3"
              style={{
                fontSize: "46px",
                fontWeight: "500",
                lineHeight: "52px",
              }}
            >
              Event
            </div>{" "}
            <div
              style={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              className="ps-3 pe-5"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat
            </div>
          </div>
        </div>
        <div className="col-md-7 "> text</div>
      </div>
    </div>
  );
}
export default Events;
